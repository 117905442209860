import React from "react";
import ReactDOM from "react-dom";

import "@fontsource/open-sans";
import "@fontsource/open-sans/600.css";
import { RedocStandalone } from "redoc";

import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <RedocStandalone
      specUrl={`${process.env.PUBLIC_URL}/openapi.yaml`}
      options={{
        hideDownloadButton: true,
        nativeScrollbars: true,
        theme: {
          colors: {
            text: {
              primary: "#262626",
            },
            primary: {
              main: "#3175C8",
            },
          },
          logo: {
            gutter: "16px",
          },
          spacing: {
            sectionVertical: 20,
          },
          typography: {
            headings: {
              fontFamily:
                '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", ' +
                '"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              fontWeight: "600",
            },
            fontFamily:
              '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", ' +
              '"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            smoothing: "auto",
          },
        },
      }}
    />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
